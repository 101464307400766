'use strict';

document.addEventListener('DOMContentLoaded', function() {

    document.querySelectorAll('.g-block--tabs').forEach(
        (block) => {

            /**
             * Init Slider
             */
            let sliderNode = block.querySelector('.g-block--tabs__slider');
            let slider = new Swiper(sliderNode, {
                allowTouchMove: false,
                spaceBetween: 20,
                speed: 600,
                watchSlidesProgress: true,
                on: {
                    init: function (swiper) {
                        const { wrapperEl, activeIndex, slides } = swiper;
                        swiperAutoHeight(swiper, wrapperEl, activeIndex, slides);
                    },
                    transitionStart: function (swiper) {
                        const { wrapperEl, activeIndex, slides } = swiper;
                        swiperAutoHeight(swiper, wrapperEl, activeIndex, slides);
                    }
                }
            });

            function swiperAutoHeight(swiper, wrapperEl, activeIndex, slides) {
                const elems = slides[activeIndex].getElementsByClassName('g-block--tab-basic__content');
                if (elems.length > 0) {
                    swiper.$wrapperEl.css('transition', `all 600ms`);
                    swiper.$wrapperEl.css('height', `${elems[0].clientHeight}px`);
                    swiper.update();
                }
            }

            /**
             * Slider Navigation
             */
            block.querySelectorAll('.g-block--tabs__categories .g-category').forEach(
                (btn) => {
                    btn.addEventListener('click', (e) => {
                        block.querySelectorAll('.g-block--tabs__categories .g-category').forEach((otherbtn) => {
                            otherbtn.classList.remove('g-active');
                        });
                        btn.classList.add('g-active');
                        slider.slideTo(btn.dataset.index);
                    });
                }
            );

            /**
             * Slider Navigation Mobile
             */
            let selectNode = block.querySelector('.g-block--tabs__categories select');
            selectNode.addEventListener('change', (e) => {
                slider.slideTo(selectNode.value);
            });
        }
    );
});